<template>
  <div>
    <b-card>
      <b-card-body
        :title="$t('KandidatiKojimaIsticuDatumi')"
      >
        <b-table
          v-if="Object.entries(kandidatiIsticu).length"
          :items="kandidatiIsticu"
          :fields="fieldsKandidati"
          striped
          responsive
          class="mb-0"
        >
          <template #cell(detalji)="row">
            <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
            <b-form-checkbox
              v-model="row.detailsShowing"
              @change="row.toggleDetails"
            />
          </template>
          <template #cell(id)="data">
            <b-button-group size="sm">
              <b-button
                variant="info"
                size="sm"
                @click="linkIzmeni(data.item.id)"
              >
                {{ $t("Izmeni") }}
              </b-button>
              <b-dropdown
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                right
                variant="outline-primary"
                :text="$t('Linkovi')"
                size="sm"
              >
                <b-dropdown-item>
                  <router-link
                    :to="{
                      name: 'autoskola-kandidat-ugovori',
                      params: { id: data.item.id }
                    }"
                  >
                    {{ $t('Ugovori') }}
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item>
                  <router-link
                    :to="{
                      name: 'autoskola-kandidat-podaci-o-obuci-izmeni',
                      params: { id: data.item.id }
                    }"
                  >
                    {{ $t('PodaciOObuci') }}
                  </router-link>
                </b-dropdown-item>
                <b-dropdown-item>
                  <router-link
                    :to="{
                      name: 'autoskola-kandidat-finansije',
                      params: { id: data.item.id }
                    }"
                  >
                    {{ $t('Finansije') }}
                  </router-link>
                </b-dropdown-item>
              </b-dropdown>

            </b-button-group>
          </template>

          <!-- full detail on click -->
          <template #row-details="row">
            <b-card>
              <b-card>
                <b-table-simple
                  small
                  responsive
                >
                  <b-tbody>
                    <b-th
                      v-if="row.item.rateKojeDolaze.length > 0"
                      colspan="2"
                    >
                      {{ $t('RateKojeDolazeNaNaplatu') }}
                    </b-th>
                    <b-tr
                      v-for="rata, key in row.item.rateKojeDolaze"
                      :key="key"
                    >
                      <b-td>
                        {{ rata.datum }}
                      </b-td>
                      <b-td>
                        {{ rata.iznos }}
                      </b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.potrebna_pomagala"
                      variant="warning"
                    >
                      <b-th class="col-3">
                        {{ $t('PotrebnaPomagala') }}
                      </b-th>
                      <b-td>{{ row.item.potrebna_pomagala_prikaz }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.isticeTeorijskiIspit"
                      variant="warning"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaTeorijskogIspita') }}
                      </b-th>
                      <b-td>{{ row.item.datumIstekaTeorijskogIspita }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.istice_lekarsko_uverenje"
                      variant="warning"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaLekarskogUverenja') }}
                      </b-th>
                      <b-td>{{ row.item.datum_isteka_lekarskog }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.obavezna_prva_pomoc"
                      variant="warning"
                    >
                      <b-th
                        colspan="2"
                        class="col-3"
                      >
                        {{ $t('ObaveznaPrvaPomoc') }}
                      </b-th>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-card>
            </b-card>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
        </b-table>
        <b-alert
          v-else
          variant="primary"
          show
        >
          <div
            class="alert-body"
            style="padding: 30px;"
          >
            <span>{{ $t('NemaKandidataKojimaIsticuDatumi') }}</span>
          </div>
        </b-alert>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-body :title="$t('KandidatiKojimaSuIstekliDatumi')">
        <b-table
          v-if="Object.entries(kandidatiIstekli).length"
          :items="kandidatiIstekli"
          :fields="fieldsKandidati"
          striped
          responsive
          class="mb-0"
        >
          <template #cell(detalji)="row">

            <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
            <b-form-checkbox
              v-model="row.detailsShowing"
              @change="row.toggleDetails"
            />
          </template>
          <template #cell(id)="data">
            <b-button-group
              size="sm"
            >
              <router-link :to="{ name: 'autoskola-zasposleni-izmeni', params: { id: data.item.id }}">
                <b-button
                  variant="info"
                  size="sm"
                >
                  {{ $t('Izmeni') }}
                </b-button>
              </router-link>
            </b-button-group>
          </template>

          <!-- full detail on click -->
          <template #row-details="row">
            <b-table-simple
              hover
              small
              responsive
            >
              <b-tbody>
                <b-tr
                  v-if="row.item.status_teorijskog_ispita === 'istekao'"
                  variant="danger"
                >
                  <b-th
                    class="col-3"
                  >
                    {{ $t('DatumIstekaTeorijskogIspita') }}
                  </b-th>
                  <b-td>{{ row.item.datumIstekaTeorijskogIspita }}</b-td>
                </b-tr>
                <b-tr
                  v-if="row.item.vazi_lekarsko"
                  variant="danger"
                >
                  <b-th class="col-3">
                    {{ $t('DatumIstekaLekarskogUverenja') }}
                  </b-th>
                  <b-td>{{ row.item.datum_isteka_lekarskog }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </template>

          <template #cell(status)="data">
            <b-badge :variant="status[1][data.value]">
              {{ status[0][data.value] }}
            </b-badge>
          </template>
        </b-table>
        <b-alert
          v-else
          variant="primary"
          show
        >
          <div
            class="alert-body"
            style="padding: 30px;"
          >
            <span>{{ $t('NemaKandidataKojimaSuIstekliDatumi') }}</span>
          </div>
        </b-alert>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-body
        :title="$t('ZaposleniIstekliDatumi')"
      >
        <div>
          <b-table
            v-if="Object.entries(zaposleniIstekli).length"
            :items="zaposleniIstekli"
            :fields="fieldsZaposleni"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(detalji)="row">

              <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              />
            </template>
            <template #cell(id)="data">
              <b-button-group
                size="sm"
              >
                <router-link :to="{ name: 'autoskola-zasposleni-izmeni', params: { id: data.item.id }}">
                  <b-button
                    variant="info"
                    size="sm"
                  >
                    {{ $t('Izmeni') }}
                  </b-button>
                </router-link>
              </b-button-group>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-table-simple
                hover
                small
                responsive
              >
                <b-tbody>
                  <b-tr
                    v-if="row.item.isteklo_lekarsko_instruktora"
                    variant="danger"
                  >
                    <b-th
                      class="col-3"
                    >
                      {{ $t('DatumIstekaLekarskogInstruktora') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_lekarskog_instruktora }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istekla_licenca_instruktora"
                    variant="danger"
                  >
                    <b-th class="col-3">
                      {{ $t('DatumIstekaLicenceInstruktora') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_licence_instruktora }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istekla_licenca_ispitivaca"
                    variant="danger"
                  >
                    <b-th class="col-3">
                      {{ $t('DatumIstekaLicenceIspitivaca') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_licence_ispitivaca }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istekla_licenca_predavaca"
                    variant="danger"
                  >
                    <b-th class="col-3">
                      {{ $t('DatumIstekaLicencePredavaca') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_licence_predavaca }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istekla_vozacka_dozvola"
                    variant="danger"
                  >
                    <b-th class="col-3">
                      {{ $t('DatumIstekaVozackeDozvole') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_vozacke_dozvole_formatirano }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istekla_tahografska_kartica"
                    variant="danger"
                  >
                    <b-th
                      class="col-3"
                    >
                      {{ $t('DatumIstekaTahografskeKartice') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_tahografske_kartice }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istekla_licna_karta"
                    variant="danger"
                  >
                    <b-th class="col-3">
                      {{ $t('DatumIstekaLicneKarte') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_licne_karte_formatirano }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istekao_sertifikat_za_potpis"
                    variant="danger"
                  >
                    <b-th class="col-3">
                      {{ $t('DatumIstekaSertifikataZaPotpis') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_sertifikata_za_potpis_fomatirano }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.isteklo_ocitavanje_tahografske_kartice"
                    variant="danger"
                  >
                    <b-th
                      class="col-3"
                    >
                      {{ $t('DatumOcitavanjaTahorafskeKartice') }}
                    </b-th>
                    <b-td>{{ row.item.datum_ocitavanja_tahografske_kartice_formatirano }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>
          </b-table>
          <b-alert
            v-else
            variant="primary"
            show
          >
            <div
              class="alert-body"
              style="padding: 30px;"
            >
              <span>{{ $t('NemaZaposlenihKojimaSuIstekliDatumi') }}</span>
            </div>
          </b-alert>
        </div>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-body
        :title="$t('ZaposleniIsticuDatumi')"
      >
        <div>
          <b-table
            v-if="Object.entries(zaposleniIsticu).length"
            :items="zaposleniIsticu"
            :fields="fieldsZaposleni"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(detalji)="row">

              <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              />
            </template>
            <template #cell(id)="data">
              <b-button-group
                size="sm"
              >
                <router-link :to="{ name: 'autoskola-zasposleni-izmeni', params: { id: data.item.id }}">
                  <b-button
                    variant="info"
                    size="sm"
                  >
                    {{ $t('Izmeni') }}
                  </b-button>
                </router-link>
              </b-button-group>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-table-simple
                hover
                small
                responsive
              >
                <b-tbody>
                  <b-tr
                    v-if="row.item.istice_lekarsko_instruktora"
                    variant="danger"
                  >
                    <b-th class="col-3">
                      {{ $t('DatumIstekaLekarskogInstruktora') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_lekarskog_instruktora }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istice_licenca_instruktora"
                    variant="danger"
                  >
                    <b-th
                      class="col-3"
                    >
                      {{ $t('DatumIstekaLicenceInstruktora') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_licence_instruktora }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istice_licenca_ispitivaca"
                    variant="danger"
                  >
                    <b-th class="col-3">
                      {{ $t('DatumIstekaLicenceIspitivaca') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_licence_ispitivaca }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istice_licenca_predavaca"
                    variant="danger"
                  >
                    <b-th class="col-3">
                      {{ $t('DatumIstekaLicencePredavaca') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_licence_predavaca }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istice_vozacka_dozvola"
                    variant="danger"
                  >
                    <b-th class="col-3">
                      {{ $t('DatumIstekaVozackeDozvole') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_vozacke_dozvole_formatirano }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istice_tahografska_kartica"
                    variant="danger"
                  >
                    <b-th class="col-3">
                      {{ $t('DatumIstekaTahografskeKartice') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_tahografske_kartice }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istice_licna_karta"
                    variant="danger"
                  >
                    <b-th class="col-3">
                      {{ $t('DatumIstekaLicneKarte') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_licne_karte_formatirano }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istice_sertifikat_za_potpis"
                    variant="danger"
                  >
                    <b-th class="col-3">
                      {{ $t('DatumIstekaSertifikataZaPotpis') }}
                    </b-th>
                    <b-td>{{ row.item.datum_isteka_sertifikata_za_potpis_fomatirano }}</b-td>
                  </b-tr>
                  <b-tr
                    v-if="row.item.istice_ocitavanje_tahografske_kartice"
                    variant="danger"
                  >
                    <b-th class="col-3">
                      {{ $t('DatumOcitavanjaTahorafskeKartice') }}
                    </b-th>
                    <b-td>{{ row.item.datum_ocitavanja_tahografske_kartice_formatirano }}</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>
          </b-table>
          <b-alert
            v-else
            variant="primary"
            show
          >
            <div
              class="alert-body"
              style="padding: 30px;"
            >
              <span>{{ $t('NemaZaposlenihKojimaIsticuDatumi') }}</span>
            </div>
          </b-alert>
        </div>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-body :title="$t('VozilaIstekliDatumi')">
        <div>
          <b-table
            v-if="Object.entries(vozilaIstekli).length"
            :items="vozilaIstekli"
            :fields="fieldsVozila"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(detalji)="row">
              <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              />
            </template>
            <template #cell(id)="data">
              <b-button-group size="sm">
                <router-link
                  :to="{
                    name: 'autoskola-vozila-izmeni',
                    params: { id: data.item.id }
                  }"
                >
                  <b-button
                    variant="info"
                    size="sm"
                  >
                    {{ $t("Izmeni") }}
                  </b-button>
                </router-link>
              </b-button-group>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-card>
                <b-table-simple
                  hover
                  small
                  responsive
                >
                  <b-tbody>
                    <b-tr
                      v-if="row.item.istekla_registracija"
                      variant="danger"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaRegistracije') }}
                      </b-th>
                      <b-td>{{ row.item.datum_isteka_registracije }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.istekao_sestomesecni_tehnicki"
                      variant="danger"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaSestomesecnogTehnickog') }}
                      </b-th>
                      <b-td>{{ row.item.datum_isteka_sestomesecnog_tehnickog_pregleda }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.istekao_redovan_servis"
                      variant="danger"
                    >
                      <b-th class="col-3">
                        {{ $t('RedovanServisNa') }} {{ row.item.km_redovan_servis }}
                      </b-th>
                      <b-td>{{ $t('StanjeNaPutometru') }} {{ row.item.stanje_putometar }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.istekla_prva_pomoc"
                      variant="danger"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaPrvePomoci') }}
                      </b-th>
                      <b-td>{{ row.item.prva_pomoc ? row.item.datum_isteka_prve_pomoci : $t('NemaPrvuPomoc') }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.istekao_pp_aparat"
                      variant="danger"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaPPAparata') }}
                      </b-th>
                      <b-td>{{ row.item.datum_isteka_pp_aparata }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.istekao_servis_tahografa"
                      variant="danger"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumServisaTahografa') }}
                      </b-th>
                      <b-td>{{ row.item.datum_servisa_tahografa }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.isteklo_preuzimanje_podataka_sa_digitalnog_tahografa"
                      variant="danger"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumPreuzimanjaPodatakaTahograf') }}
                      </b-th>
                      <b-td>{{ row.item.datum_preuzimanja_podataka_tahograf }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.istekao_atest_za_tng"
                      variant="danger"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumAtestaTng') }}
                      </b-th>
                      <b-td>{{ row.item.datum_atesta_tng }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-card>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>
          </b-table>
          <b-alert
            v-else
            variant="primary"
            show
          >
            <div
              class="alert-body"
              style="padding: 30px;"
            >
              <span>{{ $t('NemaVozilaKojimaSuIstekliDatumi') }}</span>
            </div>
          </b-alert>
        </div>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-body :title="$t('VozilaIsticuDatumi')">
        <div>
          <b-table
            v-if="Object.entries(vozilaIsticu).length"
            :items="vozilaIsticu"
            :fields="fieldsVozila"
            striped
            responsive
            class="mb-0"
          >
            <template #cell(detalji)="row">
              <!-- As `row.showDetails` is one-way, we call the toggleDetails function on @change -->
              <b-form-checkbox
                v-model="row.detailsShowing"
                @change="row.toggleDetails"
              />
            </template>
            <template #cell(id)="data">
              <b-button-group size="sm">
                <router-link
                  :to="{
                    name: 'autoskola-vozila-izmeni',
                    params: { id: data.item.id }
                  }"
                >
                  <b-button
                    variant="info"
                    size="sm"
                  >
                    {{ $t("Izmeni") }}
                  </b-button>
                </router-link>
              </b-button-group>
            </template>

            <!-- full detail on click -->
            <template #row-details="row">
              <b-card>
                <b-table-simple
                  hover
                  small
                  responsive
                >
                  <b-tbody>
                    <b-tr
                      v-if="row.item.istice_registracija"
                      variant="warning"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaRegistracije') }}
                      </b-th>
                      <b-td>{{ row.item.datum_isteka_registracije }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.istice_sestomesecni_tehnicki"
                      variant="warning"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaSestomesecnogTehnickog') }}
                      </b-th>
                      <b-td>{{ row.item.datum_isteka_sestomesecnog_tehnickog_pregleda }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.istice_redovan_servis"
                      variant="warning"
                    >
                      <b-th class="col-3">
                        {{ $t('RedovanServisNa') }} {{ row.item.km_redovan_servis }}
                      </b-th>
                      <b-td>{{ $t('StanjeNaPutometru') }} {{ row.item.stanje_putometar }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.istice_prva_pomoc"
                      variant="warning"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaPrvePomoci') }}
                      </b-th>
                      <b-td>{{ row.item.prva_pomoc ? row.item.datum_isteka_prve_pomoci : $t('NemaPrvuPomoc') }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.istice_pp_aparat"
                      variant="warning"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumIstekaPPAparata') }}
                      </b-th>
                      <b-td>{{ row.item.datum_isteka_pp_aparata }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.istice_servis_tahografa"
                      variant="danger"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumServisaTahografa') }}
                      </b-th>
                      <b-td>{{ row.item.datum_servisa_tahografa }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.istice_preuzimanje_podataka_sa_digitalnog_tahografa"
                      variant="warning"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumPreuzimanjaPodatakaTahograf') }}
                      </b-th>
                      <b-td>{{ row.item.datum_preuzimanja_podataka_tahograf }}</b-td>
                    </b-tr>
                    <b-tr
                      v-if="row.item.istice_atest_za_tng"
                      variant="danger"
                    >
                      <b-th class="col-3">
                        {{ $t('DatumAtestaTng') }}
                      </b-th>
                      <b-td>{{ row.item.datum_atesta_tng }}</b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-card>
            </template>

            <template #cell(status)="data">
              <b-badge :variant="status[1][data.value]">
                {{ status[0][data.value] }}
              </b-badge>
            </template>
          </b-table>
          <b-alert
            v-else
            variant="primary"
            show
          >
            <div
              class="alert-body"
              style="padding: 30px;"
            >
              <span>{{ $t('NemaVozilaKojimaIsticuDatumi') }}</span>
            </div>
          </b-alert>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BFormCheckbox,
  BButton,
  BCardBody,
  BBadge,
  BButtonGroup,
  BCard,
  BDropdown,
  BDropdownItem,
  BAlert,

} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BTable,
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BButton,
    BFormCheckbox,
    BCardBody,
    BBadge,
    BButtonGroup,
    BCard,
    BDropdown,
    BDropdownItem,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fieldsKandidati: [
        { key: 'detalji', label: '', thStyle: { width: '10px' } },
        { key: 'idkandidata', label: this.$i18n.t('IdKandidata') },
        { key: 'ime_prezime', label: this.$i18n.t('ImePrezime') },
        { key: 'telefon', label: this.$i18n.t('Telefon') },
        { key: 'status', label: this.$i18n.t('status') },
        { key: 'id', label: 'Akcije' },
      ],
      fieldsZaposleni: [{ key: 'detalji', label: '', thStyle: { width: '10px' } }, { key: 'ime_prezime', label: this.$i18n.t('ImePrezime') }, { key: 'mobilni', label: this.$i18n.t('Mobilni') }, { key: 'id', label: 'Akcije' }],
      fieldsVozila: [
        { key: 'detalji', label: '', thStyle: { width: '10px' } },
        'marka',
        'tip',
        { key: 'regbroj', label: 'Registarski broj' },
        { key: 'pocregistracije', label: 'PocetakRegistracije' },
        { key: 'datum_isteka_registracije', label: this.$i18n.t('KrajRegistracije') },
        { key: 'id', label: 'Akcije' },
      ],
      /* eslint-disable global-require */
      kandidatiIsticu: [],
      kandidatiIstekli: [],
      vozilaIsticu: [],
      vozilaIstekli: [],
      zaposleniIsticu: [],
      zaposleniIstekli: [],
      status: [{
        aktivan: this.$i18n.t('Aktivan'), neaktivan: this.$i18n.t('Neaktivan'), probni: this.$i18n.t('Probni'),
      },
      {
        aktivan: 'light-success', neaktivan: 'light-danger', probni: 'light-warning',
      }],
    }
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    pokupiListu() {
      this.$http.get('/autoskole-administratori/sve-notifikacije').then(response => {
        this.kandidatiIsticu = response.data.kandidatiIsticu
        this.kandidatiIstekli = response.data.kandidatiIstekli
        this.zaposleniIsticu = response.data.zaposleniIsticu
        this.zaposleniIstekli = response.data.zaposleniIstekli
        this.vozilaIsticu = response.data.vozilaIsticu
        this.vozilaIstekli = response.data.vozilaIstekli
      })
    },
    linkIzmeni(kandidatId) {
      this.$router.push({ name: 'autoskola-kandidati-izmeni', params: { id: kandidatId } })
    },
  },
}
</script>
